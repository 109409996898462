import { ChangeEvent, useEffect, useRef, useState } from "react";

import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { formatTime } from "../helpers/utils";
import ErrorBanner from "./ErrorBanner";
import { HttpClient } from "../helpers/httpClient";
import dayjs from "dayjs";
import CheckIcon from "@mui/icons-material/Check";
import DayViewColumn from "./DayViewColumn";
import { fetchAuthSession } from "aws-amplify/auth";
import { AWSCognitoCredentialsProvider } from "../websockets/CredentialsProvider";
import { connect, createClient } from "../websockets/Websockets";
import { mqtt5 } from "aws-iot-device-sdk-v2";
import { useWebsocketContext } from "../providers/WebsocketProvider";
import { toUtf8 } from "@aws-sdk/util-utf8-browser";

const MAX_RIDERS_PER_PAGE = 18;

export type LessonOnDay = {
  lessonId: number;
  lessonTime: string;
  lessonNotes: string;
  lessonLevel: number;
  entries: {
    personId: string;
    personName: string;
    horseChangedTime: string;
    horseId: string;
    horseName: string;
    horseIn: boolean;
    notes: string;
    entryModifications: string;
    saddle: {
      saddle: string;
      pads: string;
      additions: string;
    };
  }[];
  notes: string;
};
export type Day = LessonOnDay[];

const dayString = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function concatIfBothNotEmpty(str1: string, str2: string) {
  if (str1.trim() !== "" && str2.trim() !== "") {
    return str1 + ", " + str2;
  } else if (str1) {
    return str1;
  }

  return str2;
}

function determineColums(day: Day) {
  let page = 0;
  let currentNumRiders = 0;

  const cols: Day[] = [[]];
  for (let i = 0; i < day?.length; i++) {
    if (currentNumRiders + day[i].entries.length <= MAX_RIDERS_PER_PAGE) {
      currentNumRiders += day[i].entries.length;
      cols[page].push(day[i]);
      continue;
    }

    currentNumRiders = day[i].entries.length;
    page += 1;
    cols.push([day[i]]);
  }

  return cols;
}

function findEntryAndUpdate(
  day: Day[],
  update: { lesson: string; rider: string; data: {} }
) {
  let currentBoard = [...day];
  for (let i = 0; i < currentBoard.length; i++) {
    for (let j = 0; j < currentBoard[i].length; j++) {
      if (String(currentBoard[i][j].lessonId) === String(update.lesson)) {
        for (let k = 0; k < currentBoard[i][j].entries.length; k++) {
          if (currentBoard[i][j].entries[k].personId == update.rider) {
            currentBoard[i][j].entries[k] = {
              ...currentBoard[i][j].entries[k],
              ...update.data,
            };
          }
        }
      }
    }
  }

  return currentBoard;
}

function DayView() {
  const [countdown, setCountdown] = useState(30);
  const [errorMessage, setErrorMessage] = useState("");
  const [day, setDay] = useState<Day[]>();
  const [date, setDate] = useState<dayjs.Dayjs | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { client, loadingWeb } = useWebsocketContext();

  const dayRef = useRef(day);

  useEffect(() => {
    dayRef.current = day; // Update ref whenever `day` changes
  }, [day]);

  async function getBoard(refresh: boolean) {
    setErrorMessage("");
    let dateToFetch = date ?? dayjs();
    if (refresh || date === null) {
      try {
        const result: {
          boardDate: { Time: string; Valid: boolean };
          page: number;
        } = await HttpClient.get("/board/control");
        dateToFetch = result.boardDate.Valid
          ? dayjs(result.boardDate.Time.split("T")[0])
          : dayjs();

        setDate(dateToFetch);
        setCurrentPage(result.page);
        return;
      } catch {
        setDate(dateToFetch ?? dayjs());
        return;
      }
    }

    try {
      const result: Day = await HttpClient.get(
        `/board/day/${dayString[dateToFetch.day()]}`,
        {},
        {
          date: dateToFetch.format("YYYY-MM-DD"),
        }
      );

      setDay(determineColums(result));
      setCountdown(30);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  function handleMessage(payload: mqtt5.Payload | undefined, board: Day[]) {
    if (typeof payload === "string") {
      const obj = JSON.parse(payload);

      if (obj.type === "dateChange") {
        setDate(dayjs(obj.date));
        setCurrentPage(1);
      }

      if (obj.type === "pageChange") {
        setCurrentPage(Number(obj.page));
      }

      if (obj.type === "horseChange" && board) {
        const currentBoard = findEntryAndUpdate(board, {
          lesson: obj.data.lesson,
          rider: obj.data.rider,
          data: {
            horseName: obj.data.horseName,
            horseId: obj.data.horseId,
            horseIn: false,
          },
        });
        setDay(currentBoard);
      }

      if (obj.type === "horseInChange" && board) {
        const currentBoard = findEntryAndUpdate(board, {
          lesson: obj.data.lesson,
          rider: obj.data.rider,
          data: {
            horseIn: obj.data.horseIn,
          },
        });
        setDay(currentBoard);
      }

      if (obj.type === "riderAbsentChange" && board) {
        const currentBoard = findEntryAndUpdate(board, {
          lesson: obj.data.lesson,
          rider: obj.data.rider,
          data: {
            entryModifications: obj.data.riderAbsent ? "riderAbsent" : "",
          },
        });
        setDay(currentBoard);
      }

      if (obj.type === "horseNoteChange" && board) {
        const currentBoard = findEntryAndUpdate(board, {
          lesson: obj.data.lesson,
          rider: obj.data.rider,
          data: {
            notes: obj.data.notes,
          },
        });
        setDay(currentBoard);
      }

      if (obj.type === "horseSaddleChange" && board) {
        const currentBoard = findEntryAndUpdate(board, {
          lesson: obj.data.lesson,
          rider: obj.data.rider,
          data: {
            saddle: {
              saddle: obj.data.saddle,
              pads: obj.data.pads,
              additions: obj.data.additions,
            },
          },
        });
        setDay(currentBoard);
      }

      if (obj.type === "lessonNoteChange" && day) {
        let currentBoard = [...day];
        for (let i = 0; i < currentBoard.length; i++) {
          for (let j = 0; j < currentBoard[i].length; j++) {
            if (
              String(currentBoard[i][j].lessonId) === String(obj.data.lesson)
            ) {
              currentBoard[i][j].lessonNotes = obj.data.notes;
            }
          }
        }
        setDay(currentBoard);
      }
    } else {
      console.log("invalid message", payload);
    }
  }

  useEffect(() => {
    getBoard(false);
  }, [date]);

  useEffect(() => {
    if (countdown <= 0) return;

    const timerId = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [countdown]);

  useEffect(() => {
    if (countdown === 0) {
      getBoard(true);
    }
  }, [countdown]);

  useEffect(() => {
    const messageListener = (eventData: mqtt5.MessageReceivedEvent): void => {
      // Whenever a message is received, use the updated dayRef.current
      handleMessage(
        toUtf8(eventData.message.payload as Buffer),
        dayRef.current!
      );
    };

    // Attach the listener if no existing listener
    if (client && client.listeners("messageReceived").length === 0) {
      client.on("messageReceived", messageListener);
    }

    // Clean up listener when component unmounts or dependencies change
    return () => {
      if (client && client.listeners("messageReceived").length > 0) {
        client.off("messageReceived", messageListener);
      }
    };
  }, [client]);

  if (loadingWeb || client === null || !day) {
    return null;
  }

  return (
    <Box margin={"1em"} marginLeft={"2em"} marginRight={"2em"}>
      <Grid item>
        <Typography
          fontWeight={"bold"}
          variant="h2"
          gutterBottom
          align="center"
          sx={{ margin: "0px" }}
        >
          {date ? dayString[date.day()] : ""}
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <DayViewColumn lessons={day[currentPage - 1]} />
        </Grid>
        <Grid item xs={6}>
          <DayViewColumn lessons={day[currentPage]} />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Typography
            fontWeight={"bold"}
            variant="h6"
            gutterBottom
            align="center"
            sx={{ margin: "0px" }}
          >
            Refreshing data in: {countdown} secs
          </Typography>
          <Typography
            fontWeight={"bold"}
            variant="h6"
            gutterBottom
            align="center"
            sx={{ margin: "0px", color: "red" }}
          >
            {errorMessage
              ? `Failed to fetch data on last refresh: ${errorMessage}`
              : ""}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DayView;
